// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/

:root {

  //White mode base
  --ion-background-color: var(--ion-color-background);
  --ion-background-color-rgb: var(--ion-color-background-rgb);
  --ion-text-color: var(--ion-color-text);
  --ion-text-color-rgb: var(--ion-color-text-rgb);
  --ion-border-color: var(--ion-color-border);
  --button-color:  var(--ion-color-text) !important;
  /** app's defined colors **/
  --ion-color-background: #F9F9F9;
  --ion-color-background-rgb: 250,250,250;
  --ion-color-background-shade: #ececec;
  --ion-color-background-shade-rgb: 236,236,236;
  --ion-color-border: #d7d8da;
  --ion-color-border-rgb: 215, 215, 215;
  --ion-color-text: #5c5c5c;
  --ion-color-text-rgb: 92,92,92;
  --ion-color-text-blend-primary: var(--ion-color-primary-shade);

  /** primary **/
  --ion-color-primary:#5833f9;
  --ion-color-primary-rgb: 88, 51, 249;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #ced0ed;
  --ion-color-primary-tint: #ced0ed;

  /** secondary **/
  --ion-color-secondary: #45D0FF;
  --ion-color-secondary-rgb: 12, 209, 232;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #5D12FF;
  --ion-color-secondary-tint: #24d6ea;
  /** tertiary **/
  --ion-color-tertiary: #b174ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #c074ff;
  --ion-color-tertiary-tint: #45D0FF;
  --ion-color-orange: #FF773D;
  --ion-color-orange-rgb: 232, 144, 0;
  --ion-color-orange-contrast: #ffffff;
  --ion-color-orange-contrast-rgb: 255, 255, 255;
  --ion-color-orange-shade: #FF773D;
  --ion-color-orange-tint: #FF773D;
  /** success **/
  --ion-color-success: #5833f9;
  --ion-color-success-rgb: 112, 255, 135;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #50C970;
  --ion-color-success-tint: #50C970;
  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;
  /** danger **/
  --ion-color-danger: #b80000;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #c80000;
  --ion-color-danger-tint: #FF1F4C;
  /** dark **/
  --ion-color-dark: #000000;
  --ion-color-dark-rgb: 0, 0, 0;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1a1a1a;
  --ion-color-dark-tint: #383a3e;
  --ion-color-gray-accent:#969598;
  
  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;
  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;



  --ion-color-step-50: #f3f3f3;
  --ion-color-step-100: #e8e8e8;
  --ion-color-step-150: #dcdcdc;
  --ion-color-step-200: #d1d1d1;
  --ion-color-step-250: #c5c5c5;
  --ion-color-step-300: #b9b9b9;
  --ion-color-step-350: #aeaeae;
  --ion-color-step-400: #a2a2a2;
  --ion-color-step-450: #979797;
  --ion-color-step-500: #8b8b8b;
  --ion-color-step-550: #7f7f7f;
  --ion-color-step-600: #747474;
  --ion-color-step-650: #8b8b8b;
  --ion-color-step-700: #5d5d5d;
  --ion-color-step-750: #515151;
  --ion-color-step-800: #454545;
  --ion-color-step-850: #3a3a3a;
  --ion-color-step-900: #2e2e2e;
  --ion-color-step-950: #232323;
  



  /*
   * Dark Colors
   * -------------------------------------------
   */

  body.dark {
    --ion-background-color: var(--ion-color-background);
    --ion-background-color-rgb: var(--ion-color-background-rgb);
    --ion-text-color: var(--ion-color-text);
    --ion-text-color-rgb: var(--ion-color-text-rgb);
    --ion-border-color: var(--ion-color-border);
    --button-color:  var(--ion-color-text) !important;


    /** app's defined colors **/
    --ion-color-background: #171733;
    --ion-color-background-rgb: 23, 23, 51;
    --ion-color-background-shade: #101028;
    --ion-color-background-shade-rgb: 16, 16, 40;
    --ion-color-border:#0f0f0f;
    --ion-color-border-rgb: 15, 15, 15;
    --ion-color-text: var(--ion-color-light);
    --ion-color-text-rgb: var(--ion-color-light-rgb);
    --ion-color-text-blend-primary: var(--ion-color-primary);

    /** primary (dark mode) **/
    --ion-color-primary:#5833f9;
    --ion-color-primary-rgb: 88, 51, 249;
    --ion-color-primary-contrast: #000000;
    --ion-color-primary-contrast-rgb: 0, 0, 0;
    --ion-color-primary-shade: #ced0ed;
    --ion-color-primary-tint: #ced0ed;

    /** light (dark mode) **/
    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 244, 244;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;


    --ion-color-step-50: #232323;
    --ion-color-step-100: #2e2e2e;
    --ion-color-step-150: #3a3a3a;
    --ion-color-step-200: #454545;
    --ion-color-step-250: #515151;
    --ion-color-step-300: #5d5d5d;
    --ion-color-step-350: #8b8b8b;
    --ion-color-step-400: #747474;
    --ion-color-step-450: #7f7f7f;
    --ion-color-step-500: #8b8b8b;
    --ion-color-step-550: #979797;
    --ion-color-step-600: #a2a2a2;
    --ion-color-step-650: #aeaeae;
    --ion-color-step-700: #b9b9b9;
    --ion-color-step-750: #c5c5c5;
    --ion-color-step-800: #d1d1d1;
    --ion-color-step-850: #dcdcdc;
    --ion-color-step-900: #e8e8e8;
    --ion-color-step-950: #f3f3f3;


  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body.dark {

    --ion-background-color: var(--ion-color-background);
    --ion-background-color-rgb: var(--ion-color-background-rgb);
    --ion-text-color: var(--ion-color-text);
    --ion-text-color-rgb: var(--ion-color-text-rgb);
    --ion-border-color: var(--ion-color-border);
    --button-color:  var(--ion-color-text) !important;


    /** app's defined colors **/
    --ion-color-background: #171733;
    --ion-color-background-rgb: 23, 23, 51;
    --ion-color-background-shade: #101028;
    --ion-color-background-shade-rgb: 16, 16, 40;
    --ion-color-border:#0f0f0f;
    --ion-color-border-rgb: 15, 15, 15;
    --ion-color-text: var(--ion-color-light);
    --ion-color-text-rgb: var(--ion-color-light-rgb);
    --ion-color-text-blend-primary: var(--ion-color-primary);

    /** primary (dark mode) **/
    --ion-color-primary:#ced0ed;
    --ion-color-primary-rgb: 88, 51, 249;
    --ion-color-primary-contrast: #000000;
    --ion-color-primary-contrast-rgb: 0, 0, 0;
    --ion-color-primary-shade: #5833f9;
    --ion-color-primary-tint: #5833f9;

    /** light (dark mode) **/
    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 244, 244;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;


    --ion-color-step-50: #232323;
    --ion-color-step-100: #2e2e2e;
    --ion-color-step-150: #3a3a3a;
    --ion-color-step-200: #454545;
    --ion-color-step-250: #515151;
    --ion-color-step-300: #5d5d5d;
    --ion-color-step-350: #8b8b8b;
    --ion-color-step-400: #747474;
    --ion-color-step-450: #7f7f7f;
    --ion-color-step-500: #8b8b8b;
    --ion-color-step-550: #979797;
    --ion-color-step-600: #a2a2a2;
    --ion-color-step-650: #aeaeae;
    --ion-color-step-700: #b9b9b9;
    --ion-color-step-750: #c5c5c5;
    --ion-color-step-800: #d1d1d1;
    --ion-color-step-850: #dcdcdc;
    --ion-color-step-900: #e8e8e8;
    --ion-color-step-950: #f3f3f3;

  }

  .ios body.dark ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
    --ion-item-background: var(--ion-color-step-150);
  }


  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  .md body.dark {
    --ion-background-color: var(--ion-color-background);
    --ion-background-color-rgb: var(--ion-color-background-rgb);
    --ion-text-color: var(--ion-color-text);
    --ion-text-color-rgb: var(--ion-color-text-rgb);
    --ion-border-color: var(--ion-color-border);
    --button-color:  var(--ion-color-text) !important;


    /** app's defined colors **/
    --ion-color-background: #171733;
    --ion-color-background-rgb: 23, 23, 51;
    --ion-color-background-shade: #101028;
    --ion-color-background-shade-rgb: 16, 16, 40;
    --ion-color-border:#0f0f0f;
    --ion-color-border-rgb: 15, 15, 15;
    --ion-color-text: var(--ion-color-light);
    --ion-color-text-rgb: var(--ion-color-light-rgb);
    --ion-color-text-blend-primary: var(--ion-color-primary);

    /** primary (dark mode) **/
    --ion-color-primary:#ced0ed;
    --ion-color-primary-rgb: 88, 51, 249;
    --ion-color-primary-contrast: #000000;
    --ion-color-primary-contrast-rgb: 0, 0, 0;
    --ion-color-primary-shade: #5833f9;
    --ion-color-primary-tint: #5833f9;

    /** light (dark mode) **/
    --ion-color-light: #F9F9F9;
    --ion-color-light-rgb: 244, 244, 244;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;


    --ion-color-step-50: #232323;
    --ion-color-step-100: #2e2e2e;
    --ion-color-step-150: #3a3a3a;
    --ion-color-step-200: #454545;
    --ion-color-step-250: #515151;
    --ion-color-step-300: #5d5d5d;
    --ion-color-step-350: #8b8b8b;
    --ion-color-step-400: #747474;
    --ion-color-step-450: #7f7f7f;
    --ion-color-step-500: #8b8b8b;
    --ion-color-step-550: #979797;
    --ion-color-step-600: #a2a2a2;
    --ion-color-step-650: #aeaeae;
    --ion-color-step-700: #b9b9b9;
    --ion-color-step-750: #c5c5c5;
    --ion-color-step-800: #d1d1d1;
    --ion-color-step-850: #dcdcdc;
    --ion-color-step-900: #e8e8e8;
    --ion-color-step-950: #f3f3f3;

  }

}


//@media (prefers-color-scheme: dark) {
//  :root {
//    --ion-border-color: var(--ion-color-dark-shade);
//
//    /** app's defined colors **/
//    --ion-color-background: #171733;
//    --ion-color-background-rgb: 23, 23, 51;
//    --ion-color-background-shade: #101028;
//    --ion-color-background-shade-rgb: 16, 16, 40;
//    --ion-color-border:#0f0f0f;
//    --ion-color-border-rgb: 15, 15, 15;
//    --ion-color-text: var(--ion-color-light);
//    --ion-color-text-rgb: var(--ion-color-light-rgb);
//    --ion-color-text-blend-primary: var(--ion-color-primary);
//
//    /** primary (dark mode) **/
//    --ion-color-primary: #6bf178;
//    --ion-color-primary-rgb: 16, 220, 96;
//    --ion-color-primary-contrast: #000000;
//    --ion-color-primary-contrast-rgb: 0, 0, 0;
//    --ion-color-primary-shade: #4ac454;
//    --ion-color-primary-tint: #7dff8a;
//
//    /** light (dark mode) **/
//    --ion-color-light: #f4f5f8;
//    --ion-color-light-rgb: 244, 244, 244;
//    --ion-color-light-contrast: #000000;
//    --ion-color-light-contrast-rgb: 0, 0, 0;
//    --ion-color-light-shade: #d7d8da;
//    --ion-color-light-tint: #f5f6f9;
//
//
//    --ion-color-step-50: #232323;
//    --ion-color-step-100: #2e2e2e;
//    --ion-color-step-150: #3a3a3a;
//    --ion-color-step-200: #454545;
//    --ion-color-step-250: #515151;
//    --ion-color-step-300: #5d5d5d;
//    --ion-color-step-350: #8b8b8b;
//    --ion-color-step-400: #747474;
//    --ion-color-step-450: #7f7f7f;
//    --ion-color-step-500: #8b8b8b;
//    --ion-color-step-550: #979797;
//    --ion-color-step-600: #a2a2a2;
//    --ion-color-step-650: #aeaeae;
//    --ion-color-step-700: #b9b9b9;
//    --ion-color-step-750: #c5c5c5;
//    --ion-color-step-800: #d1d1d1;
//    --ion-color-step-850: #dcdcdc;
//    --ion-color-step-900: #e8e8e8;
//    --ion-color-step-950: #f3f3f3;
//
//  }
//}




// http://ionicframework.com/docs/theming/
@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';



// @font-face {
//     font-family: 'AllerDisplay';
//     src: url('./assets/fonts/AllerDisplay.ttf');
// }

@font-face {
    font-family: 'Sora';
    src: url('./assets/fonts/Sora-Regular.ttf');
}

//@font-face {
//    font-family: 'Como';
//    src: url('./assets/fonts/Como-ExtraBold.ttf');
//}

* {
    //font-family: 'Montserrat' !important;
    font-family: "Sora" !important;
}
p{
    color: var( --ion-color-text);
}
h1, h2,h3,h4,h5, ion-title, ion-select, ion-label, ion-infinite-scroll-content {
    font-family: 'Sora' !important;
    color: var( --ion-color-text);
}

ion-toolbar {
    font-family: 'Sora' !important;
    --background: var( --ion-color-background-shade) ;
}

ion-card {
    --ion-background-color: var(--ion-color-background-shade);
    --ion-border-color: var(--ion-color-border);
    //border: 1px solid var(--ion-color-step-50);
    //padding: 1.5px;
}

.emptyCard {
    background-color: var(--ion-color-background-shade);
}

.alert-wrapper {
    background-color: var(--ion-color-background-shade);
}

.custom-skeleton {
    background-color: var(--ion-color-background-shade);
    --ion-border-color: var(--ion-color-border);
}

ion-loading.loader-style {
    color: var(--ion-color-text);
    background: transparent;
    box-shadow: none;

}

.my-custom-modal-css .modal-wrapper {
    height: 60%;
    width: 80%;
    top: 25;
    position: absolute;
    display: block;
  }
